<template>
  <div
    ref="observeEntity"
    class="o-banner-carousel"
  >
    <MCarousel
      v-if="items?.length && !loading"
      loop
      :break-points="validBreakpoints"
      :items-per-slide="itemsPerSlide"
      :transition="150"
      dots="in"
      animate="fade"
    >
      <ALink
        v-for="(item, index) in items"
        :key="index"
        :href="getUrl(item.url, !item.url.includes('.html'))"
        class="o-banner-carousel__item"
      >
        <AImage
          class="o-banner-carousel__image"
          :loading="index === 0 ? 'eager' : 'lazy'"
          :src="item.image?.url.replace('%t', '') || ''"
          :placeholder="[991, 520]"
          :alt="item.image?.alt"
          height="360"
          width="1416"
          :with-retina="withPixelRatio"
          with-webp
        />
        <AImage
          class="o-banner-carousel__image mobile"
          :loading="index === 0 ? 'eager' : 'lazy'"
          :src="item.image?.url.replace('%t', '_mob') || ''"
          :placeholder="[991, 520]"
          :alt="item.image?.alt"
          height="360"
          width="1416"
          :with-retina="withPixelRatio"
          with-webp
        />
      </ALink>
    </MCarousel>
    <div
      v-else
      class="o-banner-carousel__skeleton"
    >
      <div
        v-for="n in 4"
        :key="n"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useNavigate } from '@/composables/useNavigate'
// import { getImageUrlWithSize } from '@/utils/url'

import type { PropType } from 'vue'
import type { Banner } from '@/modules/nuxt-api/models/Banner'
import type { Breakpoints } from '@/composables/device'
import type { ImageSizes } from '@/utils/url'

import ALink from '@/components/atoms/Link/ALink.vue'
import AImage from '@/components/atoms/Image/AImage.vue'

import MCarousel from '@/components/molecules/Carousel/MCarousel.vue'

const props = defineProps({
  items: {
    type: Array as PropType<Banner[]>,
    default: null
  },
  breakPoints: {
    type: Object as PropType<Record<Breakpoints, number>>,
    default: () => ({})
  },
  itemsPerSlide: {
    type: Number,
    default: 0
  },
  sizes: {
    type: Object as PropType<ImageSizes>,
    required: true
  },
  withPixelRatio: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const { getUrl } = useNavigate()

const validBreakpoints = computed(() => props.items?.length === 1
  ? Object.fromEntries(
    Object.keys(props.breakPoints).map(key => ([key, 1]))
  ) as Record<Breakpoints, number>
  : props.breakPoints
)

// const getImageUrl = (url: string | undefined, isMobile = false) => {
//   if (url && props.imageFromPublic) {
//     return url
//   }
//
//   return getImageUrlWithSize(url, props.sizes, isMobile)
// }
</script>

<style lang="postcss">
.o-banner-carousel {
  --banner-carousel-max-height: 284px;
  --banner-carousel-min-height: 140px;
  --banner-carousel-width: 1192px;
  --banner-carousel-img-height: auto;
  --banner-carousel-aspect-ratio: auto;

  position: relative;
  min-height: var(--banner-carousel-min-height);

  & .carousel {
    --carousel-arrow-position: var(--spacer-3xs);
    --carousel-item-width: var(--banner-carousel-width);

    max-height: var(--banner-carousel-max-height);

    & > div:first-child {
      position: relative;
      min-height: var(--banner-carousel-min-height);
      max-height: var(--banner-carousel-max-height);
      border-radius: var(--border-radius-lg);
      isolation: isolate;

      & > .carousel__list img {
        aspect-ratio: var(--banner-carousel-aspect-ratio);
      }

      &::after {
        content: "";
        position: absolute;
        z-index: 1;
        background-color: var(--bg-shadow-base);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s linear;
        inset: 0;
      }
    }

    & > button {
      opacity: 0;
      transition: opacity 0.3s linear;
    }
  }

  &__item {
    height: 100%;
    transform: translateZ(0) scale(1, 1);

    /* Fix flickering during transition on iphone */
    backface-visibility: hidden;

    & > .image {
      height: 100%;
    }
  }

  &__image {
    object-fit: cover;
    max-width: var(--banner-carousel-width);
    height: var(--banner-carousel-img-height);
    min-height: 100%;

    &.mobile {
      display: none;
    }
  }

  &__skeleton {
    position: absolute;
    display: flex;
    gap: var(--spacer-3xs);
    overflow: hidden;
    width: 100%;
    min-height: var(--banner-carousel-min-height);
    max-height: var(--banner-carousel-max-height);
    inset: 0;

    div {
      flex-shrink: 0;
      width: 100%;
      max-width: var(--banner-carousel-width);
      border-radius: var(--border-radius-lg);
      isolation: isolate;

      @mixin skeleton;
    }
  }

  @media (--screen-xs) {
    &__image {
      display: none;

      &.mobile {
        display: block;
      }
    }
  }
}

@media (--screen-xs) {
  .o-banner-carousel {
    --banner-carousel-max-height: 140px;
    --banner-carousel-width: 280px;

    & .carousel {
      & > div:first-child {
        border-radius: 0;
      }

      &--full-width {
        --banner-carousel-width: 100%;
        --banner-carousel-min-height: 165px;
        --banner-carousel-max-height: 100%;
      }
    }

    &__item {
      overflow: hidden;
      border-radius: var(--border-radius-base);
    }

    &__skeleton {
      margin-right: calc(-1 * var(--spacer-xs));

      div {
        border-radius: var(--border-radius-base);
      }
    }
  }
}

@media (hover: hover) and (--screen-lg) {
  .o-banner-carousel:hover .carousel {
    & > button {
      opacity: 1;
    }

    & > div:first-child {
      &::after {
        opacity: 1;
      }
    }
  }
}
</style>
