<template>
  <div class="main-page">
    <Suspense>
      <ServerRender
        is="Heading"
        level="1"
      >
        {{ metadata?.heading }}
      </ServerRender>
    </Suspense>

    <RenderCacheable
      class="main-page__banner"
      :max-age="3600"
      :async-data-keys="['home-banners']"
    >
      <HomeBannerCarousel class="container" />
    </RenderCacheable>

    <RenderCacheable :max-age="3600" class="main-page__advantage">
      <ServerRender is="Advantages" />
    </RenderCacheable>

    <ClientOnly>
      <div class="main-page__admin-settings container">
        <AdminComponent is="a-admin-settings-popover" />
      </div>
    </ClientOnly>

    <div class="main-page__body container">
      <NuxtLazyHydrate when-idle when-visible>
        <RenderCacheable :max-age="3600">
          <MHomepageCategory more is-first-screen />
        </RenderCacheable>
      </NuxtLazyHydrate>

      <RenderCacheable
        :max-age="3600"
        :async-data-keys="['home-product-collections']"
        tag="section"
      >
        <HomeBestsellersCollections />
      </RenderCacheable>

      <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            class="main-page__stories"
            :max-age="3600"
            :async-data-keys="['home-stories']"
            :cache-tags="['home-stories']"
            tag="section"
          >
            <LazyHomeStories />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback-stories" />
        </template>
      </Suspense>

      <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            :max-age="3600"
            :async-data-keys="['home-banners']"
            :cache-tags="['home-offer-banner']"
            tag="section"
          >
            <LazyHomeOfferBannerCollections />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback-offer" />
        </template>
      </Suspense>

      <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            :max-age="3600"
            :cache-tags="[`seo-tags-${seoTagHref}`]"
            :async-data-keys="[`catalog-seo-tags-${seoTagHref}`]"
            tag="section"
          >
            <ServerRender
              is="SeoTags"
              :title="$t('other.search')"
              title-level="3"
              :tags="seoTags"
            />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback-seotags" />
        </template>
      </Suspense>

      <!-- <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            :max-age="3600"
            :cache-tags="['categories-bg']"
            :async-data-keys="['categories-carousel']"
            tag="section"
          >
            <LazyCategoryCarouselOffer :title="$t('pages.main.reason')" with-background />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback-categories" />
        </template>
      </Suspense> -->

      <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            :max-age="3600"
            :async-data-keys="['home-product-collections']"
            :cache-tags="['home-high-rating']"
            tag="section"
          >
            <LazyHomeHighRatingCollections />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback-products" />
        </template>
      </Suspense>

      <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            :max-age="3600"
            :async-data-keys="['shops']"
            :cache-tags="['home-shops']"
            tag="section"
          >
            <LazyHomeShopOffer />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback" />
        </template>
      </Suspense>

      <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            :max-age="3600"
            :async-data-keys="['home-product-collections']"
            :cache-tags="['home-gifts']"
            tag="section"
          >
            <LazyHomeGiftCollections />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback-products" />
        </template>
      </Suspense>

      <NuxtLink
        href="/wine/italy/"
        no-prefetch
      >
        <AImage
          src="/assets/images/banners/6_1416x236.png"
          mobile-src="/assets/images/banners/6_990_334.png"
          provider="public"
          width="1416"
          height="236"
          with-retina
          with-webp
        />
      </NuxtLink>

      <!-- <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            :max-age="3600"
            :async-data-keys="['home-product-collections']"
            :cache-tags="['home-novelty']"
            tag="section"
          >
            <LazyHomeNoveltyCollections />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback-products" />
        </template>
      </Suspense> -->

      <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            :max-age="3600"
            :async-data-keys="['home-banners']"
            :cache-tags="['home-bestsellers']"
            tag="section"
          >
            <LazyHomeBestsellersBannerCollections />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback-offer" />
        </template>
      </Suspense>

      <!-- <AImage
        v-if="isMscRegion"
        src="/assets/images/banners/2_1416x236.png"
        mobile-src="/assets/images/banners/2_495x172.png 495w, /assets/images/banners/2_990x344.png 990w"
        from-public
        width="1416"
        height="236"
        with-retina
        with-webp
      /> -->

      <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            :max-age="3600"
            :async-data-keys="['home-rating-categories']"
            :cache-tags="['home-rating']"
            tag="section"
            class="main-page__rating"
          >
            <LazyHomeRatingCategoryCarousel />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback-categories" />
        </template>
      </Suspense>

      <Suspense>
        <NuxtLazyHydrate :when-visible="{ rootMargin: '500px' }">
          <RenderCacheable
            :max-age="3600"
            :async-data-keys="['best-reviews']"
            :cache-tags="['home-reviews']"
            tag="section"
          >
            <LazyOReviewsCarousel />
          </RenderCacheable>
        </NuxtLazyHydrate>
        <template #fallback>
          <div class="main-page__fallback" />
        </template>
      </Suspense>
    </div>

    <LazyOBottomBar v-if="isBottomBar" btn>
      <AButton size="lg" variant="success" @click="handleClick">
        <i class="i-cart" />
        <LazyAPrice
          :regular-price="amount"
          :currency="totals?.total?.currencyCodeIso"
          size="2xs"
        />
      </AButton>
    </LazyOBottomBar>

    <div v-html="'<script type=\'application/ld+json\'>' + structuredDataJSON + '</script>'" />
  </div>
</template>

<script setup lang="ts">
import ServerRender from '@/components/helpers/ServerRender'

import { computed, defineAsyncComponent } from 'vue'
import { useAPI } from '#imports'

import { useCartStore } from '@/stores/cart'
import { useNavigate } from '@/composables/useNavigate'
import type { HomeMetadata } from '@/modules/nuxt-api/models/Metadata'

import type { InterestingSection } from '@/modules/nuxt-api/models/InterestingSection'

// Atoms
import AButton from '@/components/atoms/Button/AButton.vue'
import AImage from '@/components/atoms/Image/AImage.vue'

// Molecules
import MHomepageCategory from '@/components/molecules/HomepageCategory/MHomepageCategory.vue'

// Business
import HomeBannerCarousel from '@/components/business/home/HomeBannerCarousel.vue'
import HomeBestsellersCollections from '@/components/business/home/HomeBestsellersCollections.vue'

const LazyOBottomBar = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/organisms/BottomBar/OBottomBar.vue')
)
const LazyAPrice = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/atoms/Price/APrice.vue')
)

const LazyOReviewsCarousel = defineAsyncComponent(
  () =>
    /* @vite-ignore */ import(
      '@/components/organisms/ReviewsCarousel/OReviewsCarousel.vue'
    )
)
// const LazyCategoryCarouselOffer = defineAsyncComponent(
//   () =>
//     /* @vite-ignore */ import(
//       '@/components/business/categories/CategoryCarouselOffer.vue'
//     )
// )
const LazyHomeBestsellersBannerCollections = defineAsyncComponent(
  () =>
    /* @vite-ignore */ import(
      '@/components/business/home/HomeBestsellersBannerCollections.vue'
    )
)
const LazyHomeGiftCollections = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/business/home/HomeGiftCollections.vue')
)
const LazyHomeHighRatingCollections = defineAsyncComponent(
  () =>
    /* @vite-ignore */ import('@/components/business/home/HomeHighRatingCollections.vue')
)
// const LazyHomeNoveltyCollections = defineAsyncComponent(
//   () => /* @vite-ignore */ import('@/components/business/home/HomeNoveltyCollections.vue')
// )
const LazyHomeOfferBannerCollections = defineAsyncComponent(
  () =>
    /* @vite-ignore */ import('@/components/business/home/HomeOfferBannerCollections.vue')
)
const LazyHomeRatingCategoryCarousel = defineAsyncComponent(
  () =>
    /* @vite-ignore */ import('@/components/business/home/HomeRatingCategoryCarousel.vue')
)
const LazyHomeShopOffer = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/business/home/HomeShopOffer.vue')
)
const LazyHomeStories = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/business/home/HomeStories.vue')
)

const { navigatePush } = useNavigate()
const { totals } = useCartStore()
const { getInterestingSection } = useAPI().catalog()

const amount = computed(() => Number(totals.value?.total?.amount ?? 0))
const metadata = ref<HomeMetadata | undefined>()

const seoTagHref = '/wine/'
const seoTags = ref<InterestingSection[]>([])

await Promise.all([
  useHydrationData(`catalog-index-/${seoTagHref}/-seo-tags`, () => getInterestingSection(seoTagHref), {
    then: ({ data }) => {
      seoTags.value = data.value || []
    },
    catch: () => undefined,
    lazy: true
  }),
  useHydrationData('home-metadata', async () => {
    const { getHomeMetadata } = useAPI().meta()
    return await getHomeMetadata()
  },
  {
    then: ({ data }) => {
      metadata.value = data.value
    },
    catch: () => undefined,
    lazy: true
  })
])

const isBottomBar = computed(() => process.client ? amount.value > 0 : false)

const handleClick = (): void => {
  navigatePush('/cart.php', false)
}

const structuredDataJSON = computed(() => {
  const userAgent = useRequestHeaders()['user-agent']
  const queryInput = userAgent?.includes('Googlebot') ? 'query-input' : 'query'

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: useRequestURL().href,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate:
          `${useRequestURL().host}/catalog/?search_query={search_term_string}`
      },
      [queryInput]: 'required name=search_term_string'
    }
  }

  return JSON.stringify(structuredData)
})

useSeoMeta({
  title: () => metadata.value?.title ?? '',
  description: () => metadata.value?.description
})
</script>

<style lang="postcss">
.admin-modals-popover {
  padding: var(--spacer-4xs) 0;

  .list-item.i-edit {
    width: 224px;
  }

  @media (--screen-xs) {
    & .list-item {
      padding: var(--spacer-3xs);
    }

    & .list-item__title {
      @mixin text-base;
    }
  }
}

.main-page {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacer-8xl);

  & > .heading {
    font-size: 0;
  }

  &__admin-settings {
    order: -1;
    margin-right: 0;

    & .popover {
      padding: var(--spacer-xs) 0;

      @media (--screen-xs) {
        padding: 0 0 var(--spacer-2xs) 0;
      }
    }

    & .i-settings {
      display: flex;
      gap: var(--spacer-4xs);
      align-items: center;
      cursor: pointer;

      & .link {
        cursor: pointer;
      }
    }
  }

  &__banner {
    & .carousel {
      --carousel-item-width: 100%;
    }
  }

  .o-banner-carousel__skeleton {
    gap: var(--spacer-lg);
    padding: 0 var(--spacer-lg);
  }

  & .main-page__advantage {
    margin-top: calc(-1 * (6.5rem - var(--spacer-lg)));
    padding-bottom: var(--spacer-lg);
  }

  &__body {
    width: 100%;

    & > section + section,
    & > section + .main-page__stories {
      margin-top: var(--spacer-7xl);
    }

    & .a-stories {
      min-height: 11rem;
    }

    & > a .image {
      margin: var(--spacer-5xl) 0 var(--spacer-8xl);
    }

    & > .o-homepage-offer {
      margin: var(--spacer-8xl) 0 0;
    }

    & .main-page__shops {
      margin: var(--spacer-7xl) 0 var(--spacer-8xl);
    }
  }
}

@media (--screen-lg) {
  .main-page {
    &__banner {
      padding-top: var(--spacer-base) !important;
      padding-bottom: 6.5rem;
      background-color: var(--color-neutral-200);

      & > .o-banner-carousel {
        --banner-carousel-min-height: 360px;
        --banner-carousel-max-height: 360px;
        --banner-carousel-width: 100%;
        --banner-carousel-img-height: 100%;

        max-height: var(--banner-carousel-max-height);
      }
    }

    & .o-product-carousel-offer .carousel {
      --carousel-item-width: calc((100% - 4 * var(--spacer-base)) / 5);
    }

    & .o-category-carousel-offer {
      margin: var(--spacer-7xl) calc(-1 * var(--spacer-lg)) var(--spacer-8xl);

      & .category {
        --category-img-width: 13rem;
        --category-img-height: 13rem;
        --category-gap: var(--spacer-sm);

        background: var(--color-neutral-200);

        & picture.image {
          background: var(--color-neutral-200);
        }

        @mixin text-xl;
      }
    }

    &__shops .carousel__list {
      align-items: flex-start;
    }

    /* blocks fallbacks */
    &__fallback {
      height: 40rem;

      &-stories {
        height: 24.5rem;
      }

      &-offer {
        height: 45rem;
      }

      &-seotags {
        height: 15rem;
      }

      &-products {
        height: 40rem;
      }

      &-categories {
        height: 34rem;
      }
    }
  }
}

@media (--screen-base) {
  .main-page {
    & .o-product-carousel-offer .carousel {
      --carousel-item-width: calc((100% - 5 * var(--spacer-base)) / 6);
    }

    &__rating {
      & .image {
        display: flex;
        justify-content: center;

        & img {
          width: 200px;
        }
      }
    }
  }
}

@media (--screen-xs) {
  .main-page {
    padding-top: var(--spacer-2xs);
    padding-bottom: var(--spacer-4xl);

    &__banner {
      padding: 0 var(--spacer-xs);

      & > .o-banner-carousel {
        --banner-carousel-min-height: unset;
        --banner-carousel-max-height: unset;
        --banner-carousel-width: 100%;
        --banner-carousel-img-height: auto;
        --banner-carousel-aspect-ratio: 991 / 520;

        padding-bottom: 52.473%;

        .carousel {
          position: absolute;
          inset: 0;
        }
      }
    }

    .o-banner-carousel__skeleton {
      gap: var(--spacer-xs);
      padding: 0;
    }

    & > .main-page__advantage {
      order: 1;
      margin-top: var(--spacer-5xl);
      padding-bottom: 0;
    }

    &__body {
      & > section + section,
      & > section + .main-page__stories {
        margin-top: var(--spacer-4xl);
      }

      & .a-stories {
        --carousel-item-width: 8.5rem;

        height: 12.5rem;
      }

      & > a .image {
        overflow: hidden;
        margin: var(--spacer-5xl) 0;
        border-radius: var(--border-radius-base);
      }

      & > .o-homepage-offer {
        margin: var(--spacer-4xl) 0;

        &.banner-left {
          margin: var(--spacer-5xl) 0;
        }
      }

      & .m-homepage-category {
        margin-top: var(--spacer-base);
      }

      & .o-category-carousel-offer {
        margin: var(--spacer-5xl) 0;
      }

      & .main-page__shops {
        margin: var(--spacer-5xl) 0;
      }
    }

    &__shops {
      & > .carousel.o-carousel-offer__carousel {
        --carousel-item-width: 11.75rem;
      }
    }

    /* blocks fallbacks */
    &__fallback {
      height: 23rem;

      &-stories,
      &-categories {
        height: 19.5rem;
      }

      &-offer {
        height: 95rem;
      }

      &-seotags {
        height: 11.5rem;
      }

      &-products {
        height: 32rem;
      }
    }
  }
}

@media (--screen-xs) {
  .main-page {
    &__fallback-offer {
      height: 55rem;
    }
  }
}
</style>
